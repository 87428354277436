import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import {ProductCard, ProductCardFigure, ProductCardSku } from "../Section"

const RelatedProduct = ({ data }) => {
  return (
    <ProductCard className="card">
      <ProductCardFigure className="card-img">
        <ProductCardSku className="card-sku">{data.sku}</ProductCardSku>
        <GatsbyImage image={data.cardImage.gatsbyImageData} placeholder="blurred" alt={data.sku} />
      </ProductCardFigure>
    </ProductCard>
  )
}

export default RelatedProduct
