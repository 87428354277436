import React from 'react'
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import RelatedProduct from "../RelatedProduct"

const SlickSlider = styled.div` 
  padding: 0;
  margin:0 -15px; 
`
const SlickItem = styled.div`
  display:block !important;
  outline:none;
  max-width:100%;
  padding: 30px 5px;
  @media(min-width:768px){
    padding: 30px 15px;    
  }
  .product-card{
    margin-bottom:0;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
  }
`

const RelatedProductsCarousel = ({ data }) => {
    const settings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
      
      infinite: true,
      focusOnSelect: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '90px',      

          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '30px',      

          }
        }
      ]  
    };
    return (      
      <SlickSlider>       
        <Slider {...settings}>
          {data.map((item, i)=>
            <SlickItem key={i}><RelatedProduct data={item.node} /></SlickItem>
          )}
        </Slider>
      </SlickSlider>
    );
}

export  default RelatedProductsCarousel;
