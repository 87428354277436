import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import {Section, SectionPageTitle, SectionTitle} from "../components/Section"
import {FormPrimary} from "../components/Form"
import {LineArrowRightIcon} from "../components/Icons"
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton"
import RelatedProductsCarousel from "../components/RelatedProductsCarousel"
import FaqForm from "../components/FaqForm"
import FaqAccordion from "../components/FaqAccordion"
import BlueVector from "../images/blue-pattern.jpg"

const HeroBackground = styled.div`    
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &:after, &:before{
    content: "";
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    clip-path: ellipse(calc(100% + 120px) calc(100% - 60px) at 50% 0);
    @media (min-width: 768px) {
      clip-path: ellipse(calc(100% - 15px) calc(100% + 180px) at 100% 50%);
    }
    @media (min-width: 992px) {
      clip-path: ellipse(calc(100% - 30px) calc(100% + 180px) at 100% 50%);
    }
  }

  &:before{
		background: transparent linear-gradient(270deg, #1383C1 0%, #006EAC 100%) 0% 0% no-repeat padding-box;		
	}
	&:after{
		background:${props => props.bgAfter};
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.15;
		background-position: center right;
	}	
`
const FaqGrid = styled.div`
    position: relative;
    @media (min-width: 768px) {
        display: grid;
        align-items:start;
        grid-template-columns: 1.2fr 1fr;
    }
`

const FaqGridLeft = styled.div`
    osition: relative;    
    padding: 30px 15px;
    @media (min-width: 992px) {
        padding: 40px 30px;
    }
    @media (min-width: 1200px) {
        padding: 60px 30px; 
    }
    @media (min-width: 1600px) {
        padding: 90px 30px;
    }
    @media (min-width: 1650px) {
        padding: 90px 30px 90px calc(50vw - 810px);  
    }
`
const FaqGridRight = styled.div`
    position: relative;    
    filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.4));    
    
    padding: 30px 15px 90px 15px;
    @media (min-width: 768px) {
        padding: 30px 30px 30px 60px;
        height: calc(100vh - 64px);
        position: sticky;
        top: 64px;
    }
    @media (min-width: 992px) {
        padding: 30px 30px 40px 60px;
    }
    @media (min-width: 1200px) {
        padding: 40px 30px 60px 60px;
        height: calc(100vh - 80px);
        top: 80px;
    }
    @media (min-width: 1600px) {
        padding: 120px 30px 170px 60px;
    }
    @media (min-width: 1650px) {
        padding: 120px calc(50vw - 810px) 170px 60px;
    }
`


const FaqAccordions = styled.div`    
    .accordions-title{
        font-size: 18px;
        line-height: 30px;
    }
    .accordions-items{
        border:none;
        border-radius:20px;
        opacity:0.6;
        padding:0 15px;
        box-shadow:0 0 60px rgba(0,0,0,0.20);
        @media(min-width:768px){
            padding:0 20px;
        }
        @media(min-width:1200px){
            padding:0 25px;
        }        
        @media(min-width:1600px){
            padding:0 30px;
        }
        &.active{
            opacity:1;
            .accordions-title{
                font-size: 20px;
                line-height: 34px;
                @media(min-width:1200px){
                    font-size: 22px;
                    line-height: 32px;
                }
                @media(min-width:1600px){
                    font-size: 24px;
                    line-height: 34px;
                }
            }

        }
        + .accordions-items{
            margin-top:15px;
        }
    }
    
`

const FormSection = styled(FormPrimary)`
  max-width:360px;
  width:100%;
  margin:0 auto;
  @media (min-width: 768px) {
    max-width:300px;
  }
  @media (min-width: 992px) {
    max-width:360px;
    margin-left: auto;
    margin-right: 0;
  }
  @media (min-width: 1200px) {
    max-width:440px;
  }
  @media (min-width: 1440px) {
    max-width:530px;
  }
  .form-action{
    justify-content: center;
    @media (min-width: 768px) {
      justify-content: flex-start;
    }
  }
`

const FormTitle = styled.div`
	color:#fff;
    margin-bottom:20px; 
    font-weight:700;
    font-size:22px;
    line-height:32px; 
    text-align:center;
    @media (min-width: 768px) {
        text-align:left;
    }
    @media (min-width: 1200px) {
        font-size: 28px;
        line-height: 38px;
    }
    @media (min-width: 1600px) {
        font-size: 32px;
        line-height: 42px;
    }
`

const SectionProductsCarousel = styled(Section)`
	.container{
        @media(min-width:1650px){
            max-width: inherit;
            padding-left: calc((100% - 1650px)/ 2);
        }
    }
`
const SectionTitleLink = styled(SectionTitle)`
	display:flex;
    margin-bottom:0;
    flex-direction:column;
    justify-content:space-between;
    align-items: flex-start;
    @media (min-width: 576px) {
        flex-direction:row;
        align-items: center;
    }
    .btn{
        padding:0;
    }
`

const Faq = ({ data, location }) => {
  const faqData = data.allContentfulFaq.edges
  const productData = data.allContentfulProduct.edges
  
  return(
    <Layout location={location}>
      <Seo
        title={data.allContentfulPageData.edges[0].node.metaTitle}
        description={data.allContentfulPageData.edges[0].node.metaDescription.metaDescription}
      />
      <Section pt="0" pb="0" bgColor="#fff" className="section-faq">
        <FaqGrid>
          <FaqGridLeft>   
            <SectionPageTitle className="h2">FAQs</SectionPageTitle>
            <FaqAccordions>
              <FaqAccordion data={faqData} />
            </FaqAccordions>
          </FaqGridLeft>
          <FaqGridRight>
            <HeroBackground bgAfter={`url(${BlueVector})`}>
              <FormSection>
                <FormTitle>Are you looking for a specific answer that isn’t in our FAQ? Ask us here!</FormTitle>
                <FaqForm location={location} />
              </FormSection>
            </HeroBackground>                
          </FaqGridRight>
        </FaqGrid>
      </Section>

      <SectionProductsCarousel pt="90px" pb="60px" xpt="60px" xpb="30px" mpt="60px" mpb="10px" bgColor="#F8F8F8" className="section-carousel">
        <div className="container">
          <SectionTitleLink mb="0" className="h3">
            <span>Explore our Products </span>
            <Link to='/photos'><PrimaryLinkButton  iconAfter={<LineArrowRightIcon />} text="View All Products…" decoration="underline" /></Link>
          </SectionTitleLink>
          <RelatedProductsCarousel data={productData} />
        </div>
      </SectionProductsCarousel>
    </Layout>
  );
}


export default Faq;

export const pageQuery = graphql`
  query FaqQuery{
    allContentfulFaq(sort: {order: ASC, fields: weight}) {
      edges{
        node{
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPageData(filter: {page: {eq: "FAQ"}}) {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
    allContentfulProduct(sort: {fields: skuNumber}, limit: 10) {
      edges {
        node {
          sku
          cardImage {
            gatsbyImageData
          }
        }
      }
    }
  }
`

